import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const DEFAULT_MESSAGE = 'Error while loading data';
const ErrorOverlay = (props) => (
    <Typography variant="body1">{props.message || DEFAULT_MESSAGE}</Typography>
);

ErrorOverlay.propTypes = {
    message: PropTypes.string,
  };

export default ErrorOverlay;