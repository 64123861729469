import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Category from '@material-ui/icons/Category';
import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';

import Api from './api/Api';
import ErrorOverlay from './ErrorOverlay';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { BrowserRouter as Router, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import BusinessList from './BusinessList';
import BusinessEdit from './BusinessEdit';
import CategoriesList from './CategoriesList';
import CategoryEdit from './CategoryEdit';
import BusinessImages from './BusinessImages';
import Login from './Login';
import Sidebar from './Sidebar';

import LoadStatus from './LoadStatus';
import _ from 'lodash'

import logo from './ifind-logo-vaaka.png';

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountsStatus: LoadStatus.NOT_LOADED,
      accountsError: undefined
    };
  }

  componentDidMount() {
    this.loadAccounts();
  }

  async loadAccounts() {
    const { match } = this.props;

    this.setState({ accountsStatus: LoadStatus.LOADING, accountsError: undefined });
    try {
        const accounts = await Api.listAccounts();
        this.setState({ accounts, accountsError: LoadStatus.DONE })
    } catch(error) {
        console.error(error);
        this.setState({ accountsStatus: error, accountsError: LoadStatus.ERROR })
    }
}

  renderAccounts() {
    const { accounts } = this.state;
    const { classes } = this.props;

    return accounts.map(account => <MenuItem id={account.id} value={account.id}>{account.name}</MenuItem>);
  }
  
  handleSelectAccount = (history) => (event) => {
    history.push(`/accounts/${event.target.value}/businesses`)
  }

  handleLogout = ( history ) => {
    Api.logout();
    history.push('/login')
  }

  render() {
    const { classes } = this.props;
    const { accounts } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className={classes.root}>
            <CssBaseline />
            <Route path="/accounts" render={({ history }) => 
              <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <img className={classes.logo} src={logo}/>
                <Button variant="text" color="inherit" className={classes.button} onClick={() => this.handleLogout(history)}>Logout</Button>
              </Toolbar>
            </AppBar>}/>

            <Route path="/accounts/:accountId" component={Sidebar} />
            {!Api.isAuthenticated && <Redirect to="/login"/>}

            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/accounts/:accountId/businesses/new" component={BusinessEdit} />
                <Route path="/accounts/:accountId/businesses/:id/images" component={BusinessImages} />
                <Route path="/accounts/:accountId/businesses/:id" component={BusinessEdit} />
                <Route path="/accounts/:accountId/businesses" component={BusinessList} />
                <Route path="/accounts/:accountId/categories/new" component={CategoryEdit} />
                <Route path="/accounts/:accountId/categories/:id" component={CategoryEdit} />
                <Route path="/accounts/:accountId/categories" component={CategoriesList} />
                {accounts && accounts.length > 0 && <Route render={() => <Redirect to={`/accounts/${accounts[0].id}/businesses`} /> }/>}
              </Switch>
            </main>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

const drawerWidth = 240;

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: 'rgb(0, 0, 0)'
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

const styles = theme => ({
  root: {
    display: 'flex',
  },
  logo: {
    width: 100,
    height: 40,
    objectFit: 'contain'
  },
  button: {
    marginLeft: 'auto'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
  title: {
    flexGrow: 1
  }
});

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);