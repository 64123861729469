import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl  from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Api from './api/Api';
import LoadStatus from './LoadStatus';
import ErrorOverlay from './ErrorOverlay';

import { withRouter } from "react-router-dom";
import logo from './ifind-logo-vaaka.png';

class Login extends Component {

    constructor(){
        super()
        this.state = {

        }
    }

    async login(){
        const { username, password } = this.state;
        this.setState({ loginStatus: LoadStatus.LOADING });
        try {
            await Api.login(username, password)
            this.setState({ loginStatus: LoadStatus.DONE })
            const accounts = await Api.listAccounts();
            this.props.history.push(`/accounts/${accounts[0].id}/businesses`)
        } catch(error) {
            console.log(error);
            this.setState({ loginError: error, loginStatus: LoadStatus.ERROR })
        }
    }

    handleSubmit(event){
        event.preventDefault()
        this.login()
    }

    get isAuthenticated() {
        return false
    }

    handleSetPassword = (event) => {
        this.setState({ password: event.target.value })
    }

    handleSetUsername = (event) => {
        this.setState({ username: event.target.value })
    }

    render() {    
        const { classes } = this.props;
        const { username, password, loginStatus } = this.state;

        return (
            <Grid container className={classes.content} justify='center' alignItems='center' spacing={24}>
                <Grid item xs={12} sm={6} className={classes.loginCard}>
                    <Paper className={classes.paper}>
                        <img className={classes.logo} src={logo}/>
                        <Typography variant="title">Sign in</Typography>
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <FormControl fullWidth={true}>
                                <InputLabel htmlFor="username">Username</InputLabel>
                                <Input type="text" id="username" value={username} onChange={this.handleSetUsername} />
                            </FormControl>

                            <FormControl fullWidth={true}>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input type="password" id="password" value={password} onChange={this.handleSetPassword} />
                            </FormControl>

                            <Button type="submit" variant="contained" color="primary" className={classes.loginButton}>Sign in</Button>
                            {loginStatus == LoadStatus.LOADING && <CircularProgress />}
                            {loginStatus == LoadStatus.ERROR && <ErrorOverlay message={'Login failed. Please check your username and password'}/>}
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}
const styles = theme => ({
    paper:{
        padding: 30
    },
    content: {
         
    },
    logo: {
        width: 200,
        height: 100,
        objectFit: 'contain',
        backgroundColor: 'black',
        padding: 20,
        borderRadius: 10,
        marginBottom: 20
    },
    loginCard:{
        height: 200,
    },
    loginButton:{
        marginTop: 30
    }
});

export default withRouter(withStyles(styles)(Login));