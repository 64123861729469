import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import Api from './api/Api';
import LoadStatus from './LoadStatus';
import ErrorOverlay from './ErrorOverlay';

import _, { add } from 'lodash';

import { Editor } from 'react-draft-wysiwyg';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import { withRouter, Link } from "react-router-dom";

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

//import LocationSearchInput from './LocationSearchInput'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

import BusinessLocation from './models/BusinessLocation';
import Business from './models/Business';


const DEFAULT_COORDINATES = [60.454510, 22.264824];
const DEFAULT_ZOOM = 15;

class BusinessEdit extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            business: undefined,
            businessStatus: LoadStatus.NOT_LOADED,
            businessError: undefined,
            saveStatus: LoadStatus.NOT_LOADED,
            saveError: undefined,
            editorState: EditorState.createEmpty(),
            tag: '',
            mapStates: { },
            categories: [],
            countries: []
        }
    }
    
    get isNew() {
        return _.isNil(this.props.match.params.id);
    }

    componentDidMount() {
        if (this.isNew) {
            this.createEmptyBusiness();
        } else {
            this.loadBusiness();
        }
        this.loadCountries();
        this.loadCategories();
    }

    createEmptyBusiness() {
        const business = new Business({});

        this.setState({ business })
    }

    async loadBusiness() {
        const { match } = this.props;

        this.setState({ businessStatus: LoadStatus.LOADING, businessError: undefined });
        try {
            const business = await Api.getBusiness(match.params.id);
            const editorState = EditorState.createWithContent(stateFromHTML(business.description));
            const mapStates = this.createMapStates(business);
            this.setState({ business, editorState, mapStates, businessStatus: LoadStatus.DONE })
        } catch(error) {
            console.error(error);
            this.setState({ businessError: error, businessStatus: LoadStatus.ERROR })
        }
    }

    async loadCategories() {
        this.setState({ categoriesStatus: LoadStatus.LOADING, categoriesError: undefined });
        try {
            const categories = await Api.listCategories(this.props.match.params.accountId);
            this.setState({ categories, categoriesStatus: LoadStatus.DONE })
        } catch(error) {
            console.error(error);
            this.setState({ categoriesError: error, categoriesStatus: LoadStatus.ERROR })
        }
    }

    createMapStates(business) {
        const mapStates = {};
        _.forEach(business.locations, (location, i) => {
            mapStates[`${i}`] = this.createMapState(location);
        });
        return mapStates;
    }

    createMapState(location) {
        return {
            zoom: DEFAULT_ZOOM,
            center: { lat: location.coordinates[0], lng: location.coordinates[1] }
        };
    }

    async loadCountries() {
        const { match } = this.props;

        this.setState({ countriesStatus: LoadStatus.LOADING, countriesError: undefined });
        try {
            const countries = await Api.getAvailableCountries();
            this.setState({ countries, countriesStatus: LoadStatus.DONE })
        } catch(error) {
            console.error(error);
            this.setState({ countriesError: error, countriesStatus: LoadStatus.ERROR })
        }
    }

    handleChange = path => event => {
        const business = _.cloneDeep(this.state.business);
        _.set(business, path, event.target.value);
        this.setState({ business });
    };

    handleMarkerDrag = locationIndex => (event) => {
        const coordinates = event.latLng;        
        const business = _.cloneDeep(this.state.business);
        business.locations[locationIndex].coordinates = [coordinates.lat(), coordinates.lng()];
        this.setState({ business });
    };

    handleTagChange = event => {
        this.setState({ tag: event.target.value });
    };

    handleSave = async () => {
        const { business, editorState } = this.state;
        business.description = stateToHTML(editorState.getCurrentContent())

        if(this.isNew) {
            business.accountId = _.toInteger(this.props.match.params.accountId);
        }

        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            const savedBusiness = await Api.saveBusiness(business);
            this.props.history.push(`/accounts/${this.props.match.params.accountId}/businesses`)
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    handleSetActive = (isActive) => async () => {
        const { business } = this.state;

        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            const savedBusiness = await Api.setBusinessActive(business.id, isActive);
            this.props.history.push(`/accounts/${this.props.match.params.accountId}/businesses`)
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    handleDelete = async () => {
        const { business } = this.state;

        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            await Api.deleteBusiness(business.id);
            this.props.history.push(`/accounts/${this.props.match.params.accountId}/businesses`)
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({ editorState });
    };

    handleTagDelete = index => () => {
        const business = _.cloneDeep(this.state.business);
        business.tags.splice(index, 1)
        this.setState({ business });
    }

    handleCategoryDelete = index => () => {
        const business = _.cloneDeep(this.state.business);
        business.categories.splice(index, 1)
        this.setState({ business });
    }

    handleAddTag = (event) => {
        event.preventDefault();
        const { tag } = this.state;

        if (_.isNil(tag) || _.isEmpty(tag)) {
            return;
        }

        const business = _.cloneDeep(this.state.business);
        business.tags.push(tag);
        this.setState({ business, tag: '' });
    }

    handleAddCategory = (event) => {
        const { category } = this.state;

        const business = _.cloneDeep(this.state.business);
        business.categories.push(event.target.value);
        this.setState({ business, category: '' });
    }

    handleAddLocation = () => {
        const business = _.cloneDeep(this.state.business);
        const location = new BusinessLocation({})
        location.address = '';
        location.city = '';
        location.zipCode = '';
        location.country = 'FI';
        location.coordinates = DEFAULT_COORDINATES;
        business.locations.push(location);

        const mapStates = _.cloneDeep(this.state.mapStates);
        mapStates[`${business.locations.length - 1}`] = this.createMapState(location);

        this.setState({ business, mapStates });
    }

    renderTags = () => {
        const { business } = this.state;
        const { classes } = this.props;

        return business.tags.map((tag, index) =>
            <Chip
                id={tag}
                label={tag}
                onDelete={this.handleTagDelete(index)}
                className={classes.chip}
          />);
    }

    renderCategories = () => {
        const { business, categories } = this.state;
        const { classes } = this.props;

        return business.categories.map((categoryId, index) => {
            const category = _.find(categories, {id: categoryId});
            if (!category) return null;
            return <Chip
                id={category.id}
                label={category.name}
                className={classes.chip}
                onDelete={this.handleCategoryDelete(index)}
            />
        });
    }

    onMapBoundsChanged = (locationIndex) => ({ center, zoom }) => {
        const mapStates = _.cloneDeep(this.state.mapStates);
        _.set(mapStates, `${locationIndex}`, { zoom, center });
        this.setState({ mapStates });
    }

    handleRemoveLocation = (locationIndex) => () => {
        const business = _.cloneDeep(this.state.business);
        const mapStates = _.cloneDeep(this.state.mapStates);
        business.locations.splice(locationIndex, 1)
        delete mapStates[locationIndex];
        this.setState({ business, mapStates });
    }

    handleAutocompleteSelect = (locationIndex) => async (selection) => {
        const results = await geocodeByPlaceId(selection.place_id);
        if (results && results.length > 0) {
            const result = results[0];
            const coordinates = result.geometry.location;

            console.log(result);

            const business = _.cloneDeep(this.state.business);
            business.locations[locationIndex].coordinates[0] = coordinates.lat();
            business.locations[locationIndex].coordinates[1] = coordinates.lng();

            const streetNumber = _.find(result.address_components, (component) => component.types.includes('street_number'))
            const address = _.find(result.address_components, (component) => component.types.includes('route'))
            const city = _.find(result.address_components, (component) => component.types.includes('locality'))
            const country = _.find(result.address_components, (component) => component.types.includes('country'))
            const zipCode = _.find(result.address_components, (component) => component.types.includes('postal_code'))

            if (streetNumber || address || city || country ||zipCode) {
                if (address) {
                    let suffix = '';
                    if (streetNumber) {
                        suffix = ` ${streetNumber.short_name}`
                    }
                    business.locations[locationIndex].address = `${address.short_name}${suffix}`
                }
                if (city) {
                    business.locations[locationIndex].city = city.short_name
                }
                if (country) {
                    business.locations[locationIndex].country = country.short_name
                }
                if (zipCode) {
                    business.locations[locationIndex].zipCode = zipCode.short_name
                }
            }

            const mapStates = this.createMapStates(business);

            this.setState({ business, mapStates });
        }
    }

    renderLocations() {
        const { business, countries, mapStates } = this.state;
        const { classes } = this.props;

        return business.locations.map((location, i) => {
            const mapState = mapStates[`${i}`];
            const mapCenter = _.get(mapState, 'center');
            const mapZoom = _.get(mapState, 'zoom');

            return <Grid container className={classes.locationContainer}>
            <Grid item xs={4}>
                <form key={i} noValidate autoComplete="off">
                    <div>
                        <TextField
                        id="address"
                        label="Address"
                        className={classes.textField}
                        value={location.address}
                        onChange={this.handleChange(`locations.[${i}].address`)}
                        margin="dense"/>
                    </div>
                    <div>
                        <TextField
                        id="city"
                        label="City"
                        className={classes.textField}
                        value={location.city}
                        onChange={this.handleChange(`locations.[${i}].city`)}
                        margin="dense"/>
                    </div>
                    <div>
                        <TextField
                        id="zipCode"
                        label="ZIP code"
                        className={classes.textField}
                        value={location.zipCode}
                        onChange={this.handleChange(`locations.[${i}].zipCode`)}
                        margin="dense"/>
                    </div>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="country">Country</InputLabel>
                        <Select
                        value={location.country}
                        onChange={this.handleChange(`locations.[${i}].country`)}
                        inputProps={{
                            name: 'country',
                            id: 'country',
                        }}>
                            {countries.map(country => <MenuItem id={country.value} value={country.value}>{country.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    </form>
                    <Button variant="contained" color="default" className={classes.button} onClick={this.handleRemoveLocation(i)}>Remove location</Button>
                </Grid>
                <Grid item xs={8}>
                    <GooglePlacesAutocomplete onSelect={this.handleAutocompleteSelect(i)}/>
                    <Map
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    center={mapCenter} zoom={mapZoom} height={400} onChange={this.onMapBoundsChanged(i)}>
                        <Marker position={{lat: location.coordinates[0], lng: location.coordinates[1]}} draggable={true} onDragEnd={this.handleMarkerDrag(i)}/>
                    </Map>
                </Grid>
            </Grid>
        });
    }

    renderCategoryChoices() {
        const { categories, business } = this.state;
        return categories
            .filter(category => _.indexOf(business.categories, category.id) == -1)
            .map(category => <MenuItem id={category.id} value={category.id}>{category.name}</MenuItem>)
    }

    renderImages() {
        const { business } = this.state;
        const { classes } = this.props;

        return business.images.map(image => <img className={classes.image} src={image.image}/>)
    }

    render() {
        const { business, businessStatus, businessError, saveStatus, saveError, editorState, tag, categories } = this.state;
        const { classes } = this.props;

        if (!this.isNew) {
            if (businessStatus == LoadStatus.NOT_LOADED) {
                return null;
            } else if (businessStatus == LoadStatus.LOADING) {
                return <CircularProgress/>
            } else if (businessStatus == LoadStatus.ERROR) {
                return <ErrorOverlay/>
            }
        }
        if (!business) return null;

        return (
            <div>
                <Paper className={classes.container}>
                    <Typography className={classes.subheading} variant="title">Basic information</Typography>
                    {!this.isNew && <Typography className={classes.subheading} variant="subtitle">Status: {business.isActive ? 'Active' : 'Inactive'}</Typography>}

                    <form noValidate autoComplete="off">
                        <div>
                            <TextField
                            id="name"
                            label="Name"
                            className={classes.textField}
                            value={business.name}
                            onChange={this.handleChange('name')}
                            margin="dense"/>
                        </div>
                        <div>
                            <TextField
                            id="email"
                            label="Email"
                            className={classes.textField}
                            value={business.email}
                            onChange={this.handleChange('email')}
                            margin="dense"/>
                        </div>
                        <div>
                            <TextField
                            id="website"
                            label="Website"
                            className={classes.textField}
                            value={business.website}
                            onChange={this.handleChange('website')}
                            margin="dense"/>
                        </div>
                        <div>
                            <TextField
                            id="phone"
                            label="Phone"
                            className={classes.textField}
                            value={business.phone}
                            onChange={this.handleChange('phone')}
                            margin="dense"/>
                        </div>
                        <div>
                            <TextField
                            id="fax"
                            label="Fax"
                            className={classes.textField}
                            value={business.fax}
                            onChange={this.handleChange('fax')}
                            margin="dense"/>
                        </div>
                    </form>
                </Paper>
                <Paper className={classes.container}>
                    <Typography className={classes.subheading} variant="title">Social media</Typography>
                    <form noValidate autoComplete="off">
                        <div>
                            <TextField
                            id="facebookid"
                            label="Facebook ID"
                            className={classes.textField}
                            value={business.facebookID}
                            onChange={this.handleChange('facebookID')}
                            margin="dense"/>
                            <Typography className={classes.link} variant="paragraph"><a target="_blank" href="https://findmyfbid.com/">Find the ID here</a></Typography>
                        </div>
                        <div>
                            <TextField
                            id="instagramid"
                            label="Instagram ID"
                            className={classes.textField}
                            value={business.instagramID}
                            onChange={this.handleChange('instagramID')}
                            margin="dense"/>
                        </div>
                    </form>
                </Paper>
                <Paper className={classes.container}>
                    <form noValidate autoComplete="off">
                        <Typography className={classes.subheading} variant="title">Tags</Typography>
                        <div>
                            {this.renderTags()}
                        </div>
                        <form onSubmit={this.handleAddTag}>
                            <TextField
                            id="addTag"
                            label="Add tag"
                            className={classes.tagTextField}
                            value={tag}
                            onChange={this.handleTagChange}
                            margin="dense"/>
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>Add tag</Button>
                        </form>
                    </form>
                </Paper>
                <Paper className={classes.container}>
                    <form noValidate autoComplete="off">
                        <Typography className={classes.subheading} variant="title">Categories</Typography>
                        <div>
                            {this.renderCategories()}
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="country">Category</InputLabel>
                                <Select
                                value={''}
                                onChange={this.handleAddCategory}
                                inputProps={{
                                    name: 'categories',
                                    id: 'categories',
                                }}>
                                    {this.renderCategoryChoices()}
                                </Select>
                            </FormControl>
                        </div>
                    </form>
                </Paper>
                <Paper className={classes.container}>
                    <form noValidate autoComplete="off">
                        <Typography className={classes.subheading} variant="title">Locations</Typography>
                        {this.renderLocations()}
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.handleAddLocation}>Add location</Button>
                    </form>
                </Paper>
                <Paper className={classes.container}>
                    <Typography className={classes.subheading} variant="title">Short description</Typography>
                    <form noValidate autoComplete="off">
                        <div>
                            <TextField
                            id="shortdescription"
                            label="Short description"
                            className={classes.textField}
                            value={business.shortDescription}
                            onChange={this.handleChange('shortDescription')}
                            margin="dense"
                            multiline/>
                        </div>
                    </form>
                </Paper>
                <Paper className={classes.container}>
                    <Typography className={classes.subheading} variant="title">Long description</Typography>
                    <Editor toolbar={{
                        options: ['link'],
                    }}
                    wrapperClassName={classes.editor}
                    editorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}/>
                </Paper>
                <Paper className={classes.container}>
                    <Typography className={classes.subheading} variant="title">Images</Typography>
                    {this.renderImages()}
                    <div>
                        <Button disabled={this.isNew} component={Link} to={`/accounts/${this.props.match.params.accountId}/businesses/${business.id}/images`} variant="contained" color="default" className={classes.button}>Edit images</Button>
                        {this.isNew && <div>Business must be saved before adding images</div>}
                    </div>
                </Paper>
                <Paper className={classes.container}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSave}>Save</Button>
                    {!this.isNew && business.isActive && <Button variant="contained" color="default" className={classes.button} onClick={this.handleSetActive(false)}>Set inactive</Button>}
                    {!this.isNew && !business.isActive && <Button variant="contained" color="default" className={classes.button} onClick={this.handleSetActive(true)}>Set active</Button>}
                    {!this.isNew && <Button variant="contained" color="default" className={classes.button} onClick={this.handleDelete}>Delete</Button>}
                    {saveStatus == LoadStatus.LOADING && <CircularProgress size={30}/>}
                    {saveStatus == LoadStatus.ERROR && <ErrorOverlay message="Error saving data"/>}
                    {saveStatus == LoadStatus.DONE && <ErrorOverlay message="Data saved succesfully!"/>}
                </Paper>
            </div>
        );
    }
}

const Map = withGoogleMap(GoogleMap)

const styles = theme => ({
    container: {
        padding: theme.spacing.unit,
        margin: theme.spacing.unit,
        marginTop: theme.spacing.unit + 10,
        marginBottom: theme.spacing.unit + 10,
    },
    tagTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 200
    },
    formControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 300
      },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 300
      },
      link: {
        margin: theme.spacing.unit,
      },
      button: {
        margin: theme.spacing.unit,
      },
      subheading: {
        margin: theme.spacing.unit,
      },
      editor: {
        margin: theme.spacing.unit,
      },
      chip: {
        margin: theme.spacing.unit,
      },
      marker: {
          width: 30,
          height: 30
      },
      locationContainer: {
        marginTop: 30
      },
      image: {
        width: 200,
        height: 200,
        objectFit: 'contain',
        margin: 5
    }
});

BusinessEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withRouter(withStyles(styles)(BusinessEdit));