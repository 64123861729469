import _ from 'lodash';

export default class BusinessLocation {
    constructor(json) {
        this.id = json.id;
        this.address = json.address;
        this.zipCode = json.zip_code;
        this.city = json.city;
        this.country = json.country;
        this.coordinates = _.get(json, 'coordinates.coordinates');
    }

    get fullAddress() {
        return _.chain([this.address, this.zipCode, this.city, this.country])
            .compact()
            .join(', ')
            .value()
    }

    toJSON() {
        return {
            id: this.id,
            address: this.address,
            zip_code: this.zipCode,
            city: this.city,
            country: this.country,
            coordinates: {
                type: "Point",
                coordinates: this.coordinates
            },
        }
    }
}