import _ from 'lodash';

export default class Country {
    constructor(json) {
        this.value = json.value;
        this.name = json.display_name;
    }

    toJSON() {
        return {
            value: this.value,
            display_name: this.name
        }
    }
}