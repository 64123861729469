import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withRouter } from "react-router-dom";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Api from './api/Api';
import LoadStatus from './LoadStatus';
import ErrorOverlay from './ErrorOverlay';

import FormData from 'form-data';

import BusinessImage from './models/BusinessImage';

import _ from 'lodash';

class BusinessImages extends PureComponent {

    constructor() {
        super();

        this.state = {
            businessStatus: LoadStatus.NOT_LOADED,
            saveStatus: LoadStatus.NOT_LOADED,
            crop: {
                aspect: 5/3
            }
        }
    }

    componentDidMount() {
        this.loadData();
    }
    
    async loadData() {
        const { match } = this.props;

        this.setState({ businessStatus: LoadStatus.LOADING, businessError: undefined });
        try {
            const business = await Api.getBusiness(match.params.id);
            this.setState({ business, businessStatus: LoadStatus.DONE })
        } catch(error) {
            console.error(error);
            this.setState({ businessError: error, businessStatus: LoadStatus.ERROR })
        }
    }

    getCroppedImg(image, crop, fileName) {
        console.log(crop)
        if (crop.width === 0 || crop.height === 0) {
            crop = {
                x: 0,
                y: 0,
                width: image.width,
                height: image.height
            }
        }

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = 1200;
        canvas.height = 720;
        const ctx = canvas.getContext('2d');
   
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            1200,
            720,
        );

        // As Base64 string
        // const base64Image = canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg', 1);
        });
   }

    onCropChange = crop => {
        this.setState({ crop });
      };

    renderImages = () => {
        const { classes } = this.props;
        const { business } = this.state;

        return business.images.map(image => 
            <Paper className={classes.imageContainer}>
                <img src={image.image} className={classes.image}/>
                <div>
                    <Button disabled={image.index == 0} onClick={this.handleMoveDown(image)}>◀︎</Button>
                    <Button onClick={this.handleDelete(image)}>X</Button>
                    <Button disabled={image.index >= business.images.length-1} onClick={this.handleMoveUp(image)}>▶︎</Button>
                </div>
            </Paper>
        )
    }

    handleSave = async () => {
        const { image, business, crop } = this.state;

        let formData = new FormData();
        formData.append('business', _.toInteger(this.props.match.params.id));
        formData.append('image', await this.getCroppedImg(this.cropImageRef, crop, image.name), 'image.jpg');

        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            const savedBusiness = await Api.createBusinessImage(formData);
            this.setState({ image: null, imageURL: null, crop: null }, () => {
                this.loadData();
            })
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    handleImageChange = event => {
        const image = event.target.files[0]
        this.setState({ image, imageURL: URL.createObjectURL(image) });
    }

    handleDelete = businessImage => async event => {
        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            await Api.deleteBusinessImage(businessImage.id);
            this.loadData();
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    handleMoveUp = businessImage => async event => {
        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            await Api.moveBusinessImage(businessImage.id, businessImage.index + 1);
            this.loadData();
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    handleMoveDown = businessImage => async event => {
        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            await Api.moveBusinessImage(businessImage.id, businessImage.index - 1);
            this.loadData();
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    handleMakeMain = businessImage => async event => {
        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            await Api.makeBusinessImageMain(businessImage.id);
            this.loadData();
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    onImageLoaded = ref => {
        this.cropImageRef = ref;
    }

    render() {
        const { crop, businessStatus, image, imageURL } = this.state;
        const { classes } = this.props;
        console.log(crop);


        if (businessStatus == LoadStatus.NOT_LOADED) {
            return null;
        } else if (businessStatus == LoadStatus.LOADING) {
            return <CircularProgress/>
        } else if (businessStatus == LoadStatus.ERROR) {
            return <ErrorOverlay/>
        }

        return <React.Fragment>
            <Paper className={classes.paper}>
                <div>
                    <Typography variant="title">Business images</Typography>
                    <input type="file" name="icon" onChange={this.handleImageChange}></input>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSave}>Save</Button>
                </div>
                    <div className={crop}>
                        {imageURL && <ReactCrop imageStyle={{ maxHeight: 500 }} src={imageURL} onChange={this.onCropChange} crop={crop} onImageLoaded={this.onImageLoaded}/>}
                    </div>
            </Paper>

            {this.renderImages()}
        </React.Fragment>
    }
}

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    image: {
        width: 200,
        height: 200,
        margin: 5,
        objectFit: 'contain',
    },
    imageContainer: {
        display: 'inline-block',
        margin: 5
    }
});

BusinessImages.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withRouter(withStyles(styles)(BusinessImages));