import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import Category from '@material-ui/icons/Category';

import Api from './api/Api';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import {  Link, withRouter } from "react-router-dom";


import LoadStatus from './LoadStatus';
import _ from 'lodash'

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountsStatus: LoadStatus.NOT_LOADED,
      accountsError: undefined
    };
  }

  componentDidMount() {
    this.loadAccounts();
  }

  async loadAccounts() {
    const { match } = this.props;

    this.setState({ accountsStatus: LoadStatus.LOADING, accountsError: undefined });
    try {
        const accounts = await Api.listAccounts();
        this.setState({ accounts, accountsError: LoadStatus.DONE })
    } catch(error) {
        console.error(error);
        this.setState({ accountsStatus: error, accountsError: LoadStatus.ERROR })
    }
}

  renderAccounts() {
    const { accounts } = this.state;
    const { classes } = this.props;

    return accounts.map(account => <MenuItem id={account.id} value={account.id}>{account.name}</MenuItem>);
  }
  
  handleSelectAccount = () => (event) => {
    const { history } = this.props;
    history.push(`/accounts/${event.target.value}/businesses`)
  }

  render() {
    console.log(this.props);
    const { classes, match } = this.props;
    const { accounts } = this.state;

    return (
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left">

        <Divider />

        <List>
          { accounts && <ListItem>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="accounts">Select account</InputLabel>
                <Select
                value={match.params.accountId}
                onChange={this.handleSelectAccount()}
                inputProps={{
                    name: 'accounts',
                    id: 'accounts',
                }}>
                    {this.renderAccounts()}
                </Select>
              </FormControl>
          </ListItem>}

          <ListItem component={Link} to={`/accounts/${match.params.accountId}/businesses`} button>
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText primary='Businesses' />
          </ListItem>

          <ListItem component={Link} to={`/accounts/${match.params.accountId}/categories`} button>
            <ListItemIcon><Category /></ListItemIcon>
            <ListItemText primary='Categories' />
          </ListItem>

        </List>
      </Drawer>
    );
  }
}

const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  formControl: {
    width: '100%'
  }
});

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Sidebar));