import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import Api from './api/Api';
import LoadStatus from './LoadStatus';
import ErrorOverlay from './ErrorOverlay';

import _ from 'lodash';

import { withRouter } from "react-router-dom";
import Category from './models/Category';

class CategoryEdit extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            category: undefined,
            categoryStatus: LoadStatus.NOT_LOADED,
            categoryError: undefined,
            saveStatus: LoadStatus.NOT_LOADED,
            saveError: undefined
        }
    }
    
    get isNew() {
        return _.isNil(this.props.match.params.id);
    }

    componentDidMount() {
        if (this.isNew) {
            this.createEmpty();
        } else {
            this.loadData();
        }
    }

    createEmpty() {
        const category = new Category({});
        this.setState({ category })
    }

    async loadData() {
        const { match } = this.props;

        this.setState({ categoryStatus: LoadStatus.LOADING, categoryError: undefined });
        try {
            const category = await Api.getCategory(match.params.id);
            this.setState({ category, categoryStatus: LoadStatus.DONE })
        } catch(error) {
            console.error(error);
            this.setState({ categoryError: error, categoryStatus: LoadStatus.ERROR })
        }
    }

    handleChange = path => event => {
        const category = _.cloneDeep(this.state.category);
        _.set(category, path, event.target.value);
        this.setState({ category });
    };

    handleIconChange = event => {
        const category = _.cloneDeep(this.state.category);
        _.set(category, 'newIcon', event.target.files[0]);
        this.setState({ category });
    }

    handleSave = async () => {
        const { category } = this.state;
        if(this.isNew) {
            category.account = _.toInteger(this.props.match.params.accountId);
        }

        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            const savedCategory = await Api.saveCategory(category);
            this.props.history.push(`/accounts/${this.props.match.params.accountId}/categories`)
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    handleDelete = async () => {
        const { category } = this.state;

        this.setState({ saveStatus: LoadStatus.LOADING, saveError: undefined });
        try {
            await Api.deleteCategory(category.id);
            this.props.history.push(`/accounts/${this.props.match.params.accountId}/categories`)
        } catch(error) {
            console.log(error);
            this.setState({ saveError: error, saveStatus: LoadStatus.ERROR })
        }
    }

    render() {
        const { category, categoryStatus, categoryError, saveStatus, saveError } = this.state;
        const { classes } = this.props;

        if (!this.isNew) {
            if (categoryStatus == LoadStatus.NOT_LOADED) {
                return null;
            } else if (categoryStatus == LoadStatus.LOADING) {
                return <CircularProgress/>
            } else if (categoryStatus == LoadStatus.ERROR) {
                return <ErrorOverlay/>
            }
        }
        if (!category) return null;

        return (
            <div>
                <Paper className={classes.container}>
                    <Typography className={classes.subheading} variant="title">Basic information</Typography>

                    <form noValidate autoComplete="off">
                        <div>
                            <TextField
                            id="name"
                            label="Name"
                            className={classes.textField}
                            value={category.name}
                            onChange={this.handleChange('name')}
                            margin="dense"/>
                        </div>
                    </form>
                </Paper>

                <Paper className={classes.container}>
                    <Typography className={classes.subheading} variant="title">Icon</Typography>

                    <form noValidate autoComplete="off">
                        <div>
                            <img className={classes.icon} src={category.newIcon ? URL.createObjectURL(category.newIcon) : category.icon}/>
                        </div>
                        <input type="file" name="icon" onChange={this.handleIconChange}></input>
                    </form>
                </Paper>

                <Paper className={classes.container}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSave}>Save</Button>
                    {!this.isNew && <Button variant="contained" color="default" className={classes.button} onClick={this.handleDelete}>Delete</Button>}
                    {saveStatus == LoadStatus.LOADING && <CircularProgress size={30}/>}
                    {saveStatus == LoadStatus.ERROR && <ErrorOverlay message="Error saving data"/>}
                    {saveStatus == LoadStatus.DONE && <ErrorOverlay message="Data saved succesfully!"/>}
                </Paper>
            </div>
        );
    }
}

const styles = theme => ({
    container: {
        padding: theme.spacing.unit,
        margin: theme.spacing.unit,
        marginTop: theme.spacing.unit + 10,
        marginBottom: theme.spacing.unit + 10,
    },
    tagTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 200
    },
    formControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 300
      },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 300
    },
    button: {
        margin: theme.spacing.unit,
    },
    subheading: {
        margin: theme.spacing.unit,
    },
    icon: {
        width: 100,
        height: 100,
        objectFit: 'contain'
    },
});

CategoryEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withRouter(withStyles(styles)(CategoryEdit));