import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { Link } from "react-router-dom";

import Api from './api/Api';
import LoadStatus from './LoadStatus';
import ErrorOverlay from './ErrorOverlay';

class CategoriesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            categoriesStatus: LoadStatus.NOT_LOADED,
            categoriesError: undefined
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.accountId != this.props.match.params.accountId) {
            this.loadData();
        }
    }

    async loadData() {
        this.setState({ categoriesStatus: LoadStatus.LOADING, categoriesError: undefined });
        try {
            const categories = await Api.listCategories(this.props.match.params.accountId);
            this.setState({ categories, categoriesStatus: LoadStatus.DONE })
        } catch(error) {
            console.error(error);
            this.setState({ categoriesError: error, categoriesStatus: LoadStatus.ERROR })
        }
    }

    renderRows() {
        const { categories } = this.state;
        const { classes } = this.props;

        return categories.map(category => {
            return (
            <TableRow key={category.id} hover component={Link} to={`/accounts/${this.props.match.params.accountId}/categories/${category.id}`} style={{ textDecoration: 'none' }}>
                <TableCell>{category.id}</TableCell>
                <TableCell>{category.name}</TableCell>
                <TableCell>{<img className={classes.icon} src={category.icon}/>}</TableCell>
            </TableRow>
            );
        });
    }

    render() {
        const { categoriesStatus, categoriesError, categories } = this.state;
        const { classes } = this.props;

        if (categoriesStatus == LoadStatus.LOADING) {
            return <CircularProgress/>
        } else if (categoriesStatus == LoadStatus.ERROR) {
            return <ErrorOverlay/>
        }

        return (
            <React.Fragment>
                <div>
                    <Button component={Link} to={`/accounts/${this.props.match.params.accountId}/categories/new`} className={classes.addButton} variant="contained" color="primary">+ Add new</Button>
                </div>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Icon</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.renderRows()}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
        );
    }
}

const styles = theme => ({
    icon: {
        width: 50,
        height: 50,
        objectFit: 'contain'
    },
    addButton: {
        marginBottom: 20
    }
});

CategoriesList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CategoriesList);