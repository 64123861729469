import _ from 'lodash';
import FormData from 'form-data';

export default class Category {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.icon = json.icon;
        this.account = json.account
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            account: this.account
        }
    }

    toFormData() {
        let formData = new FormData();
        _.forOwn(this.toJSON(), (value, key) => {
            formData.append(key, value);
        });
        if (this.newIcon) {
            formData.append('icon', this.newIcon)
        }
        return formData;
    }
}