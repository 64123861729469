import axios from 'axios';
import Business from '../models/Business';
import Country from '../models/Country';
import Category from '../models/Category';
import _ from 'lodash';
import BusinessImage from '../models/BusinessImage';
import PaginationResult from '../models/PaginationResult';

const LOCAL_STORAGE_TOKEN_KEY = 'token';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

class API {
    constructor() {
        this.axios = axios.create({
            baseURL: API_URL
          });

          this.axios.interceptors.request.use(
            config => {
              if (config.baseURL === API_URL && !config.headers.Authorization) {
                const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
          
                if (token) {
                  config.headers.Authorization = `JWT ${token}`;
                }
              }
          
              return config;
            },
            error => Promise.reject(error)
        );
    }

    async getAvailableCountries() {
        const response = await this.axios.options('/businesses/');
        const choices = _.get(response.data, 'actions.POST.locations.child.children.country.choices');
        return choices.map(data => new Country(data));
    }

    async listBusinesses(accountId, searchText, categories, isActive, limit, offset) {
        let params = {
            account: accountId,
            limit,
            offset,
            ordering: '-updated_at'
        }
        if (searchText && searchText != '') {
            params.search_text = searchText
        }
        if (categories) {
            params.categories = categories
        }
        if (isActive != undefined) {
            params.is_active = isActive
        }
        const response = await this.axios.get('/businesses/', {
            params
        });
        return new PaginationResult(response.data, Business);
    }

    async getBusiness(id) {
        const response = await this.axios.get(`/businesses/${id}/`);
        return new Business(response.data);
    }

    async deleteBusiness(businessId) {
        const response = await this.axios.delete(`/businesses/${businessId}/`);
    }

    async listCategories(accountId) {
        const response = await this.axios.get('/categories/', {
            params: {
                account_id: accountId
            }
        });
        return response.data.map(data => new Category(data));
    }

    async getCategory(id) {
        const response = await this.axios.get(`/categories/${id}/`);
        return new Category(response.data);
    }

    async deleteCategory(categoryId) {
        const response = await this.axios.delete(`/categories/${categoryId}/`);
    }

    async saveCategory(category) {
        const response = category.id ?
            await this.axios.patch( `/categories/${category.id}/`, category.toFormData()) :
            await this.axios.post( `/categories/`, category.toFormData());
        return new Category(response.data);
    }

    async createBusinessImage(formData) {
        const response = await this.axios.post( `/images/`, formData);
        return new BusinessImage(response.data);
    }

    async moveBusinessImage(id, newIndex) {
        const response = await this.axios.post( `/images/${id}/move/`, { new_index: newIndex });
        return new BusinessImage(response.data);
    }

    async deleteBusinessImage(id) {
        const response = await this.axios.delete( `/images/${id}/`);
    }

    async listAccounts() {
        const response = await this.axios.get('/accounts/');
        return response.data;
    }

    async saveBusiness(business) {
        const response = business.id ? await this.axios.patch( `/businesses/${business.id}/`, business.toJSON()) : await this.axios.post( `/businesses/`, business.toJSON());
        return new Business(response.data);
    }

    async setBusinessActive(businessId, isActive) {
        const response = await this.axios.patch( `/businesses/${businessId}/`, { is_active: isActive });
        return new Business(response.data);
    }

    async login(username, password) {
        const response = await this.axios.post( '/token/', { username, password });
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, response.data.token);
        return response.data.token;
    }

    logout() {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    }

    get isAuthenticated() {
        return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) != null
    }

}
const Api = new API();
export default Api;