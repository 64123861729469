import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { Link } from "react-router-dom";

import Api from './api/Api';
import LoadStatus from './LoadStatus';
import ErrorOverlay from './ErrorOverlay';

import * as moment from 'moment';
import 'moment/locale/fi'

const DEFAULT_ROWS_PER_PAGE = 50

class BusinessList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            businessesStatus: LoadStatus.NOT_LOADED,
            businessesError: undefined,
            page: 0,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadCategories();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.accountId != this.props.match.params.accountId) {
            this.loadData();
        }
    }

    async loadData() {
        const { searchText, categoryId, isActive, rowsPerPage, page } = this.state;
        this.setState({ businessesStatus: LoadStatus.LOADING });
        try {
            const offset = page * rowsPerPage
            const result = await Api.listBusinesses(this.props.match.params.accountId, searchText, categoryId, isActive, rowsPerPage, offset);
            this.setState({ result, businessesStatus: LoadStatus.DONE })
        } catch(error) {
            console.log(error);
            this.setState({ businessesError: error, businessesStatus: LoadStatus.ERROR })
        }
    }

    async loadCategories() {
        this.setState({ categoriesStatus: LoadStatus.LOADING, categoriesError: undefined });
        try {
            const categories = await Api.listCategories(this.props.match.params.accountId);
            this.setState({ categories, categoriesStatus: LoadStatus.DONE })
        } catch(error) {
            console.error(error);
            this.setState({ categoriesError: error, categoriesStatus: LoadStatus.ERROR })
        }
    }

    getBusinessCategories(business) {
        if (!this.state.categories) {
            return null;
        }
        const categories = this.state.categories.filter(category => business.categories.includes(category.id));
        return categories.map(category => category.name).join(', ');
    }

    renderRows() {
        const { result } = this.state;

        return result.results.map(business => {
            return (
            <TableRow key={business.id} hover component={Link} to={`/accounts/${this.props.match.params.accountId}/businesses/${business.id}`} style={{ textDecoration: 'none' }}>
                <TableCell>{business.name}</TableCell>
                <TableCell>{moment(business.updatedAt).locale('fi').format('lll')}</TableCell>
                <TableCell>{this.getBusinessCategories(business)}</TableCell>
                <TableCell>{business.locations.map(location => <div>{location.fullAddress}</div>)}</TableCell>
                <TableCell>{business.isActive ? 'Active' : 'Inactive'}</TableCell>
            </TableRow>
            );
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.loadData();
    }

    handleChange = (key) => (event) => {
        this.setState({[key]: event.target.value});
    }

    renderCategoryChoices() {
        const { categories } = this.state;
        return categories
            .map(category => <MenuItem id={category.id} value={category.id}>{category.name}</MenuItem>)
    }

    handleChangePage = (event, page) => {
        this.setState({ page }, () => {
            this.loadData();
        });
    }
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
            this.loadData();
        });
      };

    reset = () => {
        this.setState({
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            page: 0,
            searchText: null,
            categoryId: null,
            isActive: null
        }, () => {
            this.loadData();
        })
    }

    render() {
        const { businessesStatus, businessesError, searchText, categories, categoryId, isActive, result, rowsPerPage, page } = this.state;
        const { classes } = this.props;

        if (businessesStatus == LoadStatus.LOADING) {
            return <CircularProgress/>
        } else if (businessesStatus == LoadStatus.ERROR) {
            return <ErrorOverlay/>
        }

        if (!result) {
            return null;
        }

        return (
            <React.Fragment>
                <Paper className={classes.searchContainer}>
                    <Grid direction="row" container>
                        <Grid item xs={6}>
                            <form className={classes.form} onSubmit={this.handleSubmit}>
                                <TextField className={classes.textField} label="Search" value={searchText} onChange={this.handleChange('searchText')}/>
                                <Button type="submit" variant="contained" color="primary">Search</Button>
                            </form>
                            <div>
                                {categories && <FormControl className={classes.formControl}>
                                    <InputLabel shrink={true} htmlFor="categories">Category</InputLabel>
                                    <Select
                                    value={categoryId}
                                    onChange={this.handleChange('categoryId')}
                                    inputProps={{
                                        name: 'categories',
                                        id: 'categories',
                                    }}>
                                        <MenuItem id={'none'} value={null}>None</MenuItem>
                                        {this.renderCategoryChoices()}
                                    </Select>
                                </FormControl>}
                            </div>
                            <div>
                                <FormControl className={classes.formControl}>
                                    <InputLabel shrink={true} htmlFor="isActive">Status</InputLabel>
                                    <Select
                                    value={isActive}
                                    onChange={this.handleChange('isActive')}
                                    inputProps={{
                                        name: 'isActive',
                                        id: 'isActive',
                                    }}>
                                        <MenuItem id={'none'} value={null}>None</MenuItem>
                                        <MenuItem id={'true'} value={true}>Active</MenuItem>
                                        <MenuItem id={'false'} value={false}>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid  item xs={6}>
                            <div className={classes.buttons}>
                                <Button component={Link} to={`/accounts/${this.props.match.params.accountId}/businesses/new`} className={classes.addButton} variant="contained" color="primary">+ New business</Button>
                                <Button className={classes.resetButton} onClick={this.reset} variant="default" color="primary">Reset search</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <Table onsel>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Updated at</TableCell>
                                <TableCell>Categories</TableCell>
                                <TableCell>Locations</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.renderRows()}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={result.count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Paper>
            </React.Fragment>
        );
    }
}

const styles = theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    addButton: {
        marginRight: 10
    },
    searchContainer: {
        marginBottom: 20,
        padding: 10,
    },
    textField: {
        width: 300,
        marginRight: 20,
        marginBottom: 10
    },
    form: {
        display: 'flex',
        alignItems: 'center'
    },
    formControl: {
        width: 300
    }
});

BusinessList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BusinessList);