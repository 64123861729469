import _ from 'lodash';
import BusinessLocation from './BusinessLocation';
import BusinessImage from './BusinessImage';
import * as moment from 'moment';

export default class Business {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.account = json.account;

        this.email = json.email;
        this.fax = json.fax;
        this.phone = json.phone;
        this.website = json.website;

        this.description = json.description;
        this.shortDescription = json.short_description;

        this.tags = json.tags || [];
        this.categories = json.categories || [];

        this.facebookID = json.facebook_id;
        this.instagramID = json.instagram_id;

        this.isActive = json.is_active;
        this.updatedAt = moment(json.updated_at).toDate();

        if (json.images) {
            this.images = json.images.map(data => new BusinessImage(data))
        } else {
            this.images = [];
        }

        if (json.locations) {
            this.locations = json.locations.map(data => new BusinessLocation(data))
        } else {
            this.locations = [];
        }
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            account: _.get(this, 'account.id') || this.accountId,

            email: this.email,
            fax: this.fax,
            phone: this.phone,
            website: this.website,

            description: this.description,
            short_description: this.shortDescription,
            tags: this.tags,
            categories: this.categories,

            facebook_id: this.facebookID,
            instagram_id: this.instagramID,

            is_active: this.isActive,

            locations: this.locations.map(location => location.toJSON())
        }
    }
}