import _ from 'lodash';

export default class BusinessImage {
    constructor(json) {
        this.id = json.id;
        this.image = json.image;
        this.business = json.business
        this.index = json.index;
    }

    toJSON() {
        return {
            id: this.id,
            business: this.business,
            index: this.index
        }
    }
}